import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { dependantProfilePage } from '../app/hospitalRedux/PatientSlice';
import api from '../controllers/endpoints/api';
import { loguserOut, timeoutFunc } from '../app/hospitalRedux/userSlice';
import { Button, ButtonGroup, Card, Col, ListGroup, Modal, Row } from 'react-bootstrap';
import bellOn from '../assets/icons/bell-on.svg';
import bellOff from '../assets/icons/bell-off.svg';
import styles from './notification.module.css';
import onIcon from '../assets/icons/onIcon.png';
import offIcon from '../assets/icons/offIcon.png';
import PasswordChangedSuccess from './pChangeSuc';
import EditPassword from '../pages/HospitalAdmin/profile/editPassword';
import { useGetNotificationsQuery, useGetUnreadNotificationsQuery, useReadNotificationMutation } from '../app/services/notification';
import { PbNotification } from '../interfaces/notification';
import { TIMEZONES } from '../constants';
import { InputPicker } from 'rsuite';
import axios from 'axios';
import { useGetHospitalDetailsQuery } from '../app/services/hospital';

const { REACT_APP_BASEURL } = process.env;

interface ITopNav {
  toggleSideMenu: () => void;
}

const TopNavTS: React.FC<ITopNav> = (props) => {
  const { toggleSideMenu } = props;
  // const pb = new PocketBase('https://pocketbase.flexipgroup.com');

  const [editPass, setEditPass] = useState(false);
  const [passSucModal, setPassSucModal] = useState(false);

  const [changed, setChanged] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');

  const token = localStorage.getItem('token') || '';
  const [refreshData, setRefreshData] = useState(false);

  const [det, setDet] = useState(false);
  const initialSeconds = 10;
  const [countNow, setCountNow] = useState(false);

  const [oldPass, setOldPass] = useState('');
  const [password, setPassword] = useState('');
  const [passwordAgain, setPasswordAgain] = useState('');
  const [passValidation, setPassValidation] = useState(false);
  const [userPas, setUserPass] = useState({ oldPassword: '', newPassword: '' });

  const [notificationList, setNotificationList] = useState<PbNotification[]>([]);

  const [isInvoiceNewPage, setIsInvoiceNewPage] = useState(false);

  const hospital = localStorage.getItem('hospital_info') as any;
  const val = hospital !== null ? JSON.parse(hospital) : null;

  let userId: string | null = localStorage.getItem('userId');

  const dispatch = useAppDispatch();

  const history = useHistory();

  const location = useLocation();

  const [latestNotif, setLatestNotif] = useState(false);
  const [showUnder30, setShowUnder30] = useState(false);
  const [showUnReadNotif, setShowUnReadNotif] = useState(false);
  const [isEditTimezoneModalOpen, setIsEditTimezoneModalOpen] = useState(false);
  const [isSubmittingTimezone, setIsSubmittingTimezone] = useState(false);
  const [countNotif, setCountNotif] = useState(0);

  const [muteAllSound, setMuteAllSound] = useState(false);
  const [playSound, setPlaySound] = useState(false);

  const {
    data: notificationGetData,
    isSuccess: notificationGetDataIsSuccess,
    isLoading: notificationGetDataIsLoading,
    isError: notificationGetDataIsError,
    refetch: notificationGetDataRefetch,
  } = useGetNotificationsQuery(userId || '');

  const { data: hospitalDetails, refetch: refetchHospitalDetails } = useGetHospitalDetailsQuery(
    hospital ? JSON.parse(hospital)?.hospitalId : ''
  );
  const [timezoneInput, setTimezoneInput] = useState(hospitalDetails?.payload?.timeZoneLabel || '');

  const timeZoneLabel = hospitalDetails?.payload?.timeZoneLabel ||
    (hospital ? JSON.parse(hospital)?.timeZoneLabel : '');

  const USER_TIMEZONE = TIMEZONES.find(
    (timezone) => timezone?.label === timeZoneLabel
  );

  const {
    data: unreadNotificationGetData,
    isSuccess: unreadNotificationGetDataIsSuccess,
    isLoading: unreadNotificationGetDataIsLoading,
    isError: unreadNotificationGetDataIsError,
    refetch: unreadNotificationGetDataRefetch,
  } = useGetUnreadNotificationsQuery(userId || '');

  const [markNotificationAsRead, { isLoading: markNotificationAsReadIsLoading }] = useReadNotificationMutation();

  const handleExitSucModal = () => {
    setPassSucModal(false);
    handleLogout();
  };

  const handleGoBack = () => {
    if (location.pathname.includes('/hospital/billing/invoice/draft/')) {
      setIsInvoiceNewPage(true);
    } else {
      history.goBack();
      setTimeout(() => {
        dispatch(dependantProfilePage(true));
      }, 2000);
    }
  };

  const pushPass = () => {
    if (!editPass) {
      // console.log('none set')
      return;
    } else {
      updateUserPass();
    }
  };

  const getUserNotificationsUnder30Days = (): void => {
    notificationGetDataIsError && notificationGetDataIsLoading && toast.error('Error fetching notifications');

    notificationGetDataIsSuccess && notificationGetData?.items && setNotificationList(notificationGetData.items);
  };

  const getUserUnreadNotificationsUnder30Days = (): void => {
    unreadNotificationGetDataIsError && unreadNotificationGetDataIsLoading && toast.error('Error fetching unread notifications');

    unreadNotificationGetDataIsSuccess && unreadNotificationGetData?.items && setNotificationList(unreadNotificationGetData.items);
  };

  useEffect(() => {
    getUserNotificationsUnder30Days();
  }, [notificationGetData, userId]);

  useEffect(() => {
    showUnReadNotif ? getUserUnreadNotificationsUnder30Days() : getUserNotificationsUnder30Days();
  }, [notificationGetData, unreadNotificationGetData, showUnReadNotif]);

  const handleMarkAsReadNotification = async (notification: PbNotification): Promise<void> => {
    await markNotificationAsRead(notification)
      .then(async (result: any) => {
        result?.data?.path !== undefined
          ? (await notificationGetDataRefetch()) && (await unreadNotificationGetDataRefetch()) && history.push(`/hospital${result?.data.path}`)
          : toast.error(result?.error?.data?.message);
      })
      .catch((error: any) => console.error('Error from mark notification as read: ', error));
  };

  const handleMarkAllAsReadNotification = async (): Promise<void> => {
    for (const notification of unreadNotificationGetData?.items || []) {
      await markNotificationAsRead(notification)
        .then(async (result: any) => {
          result?.data?.path !== undefined
            ? (await notificationGetDataRefetch()) && (await unreadNotificationGetDataRefetch())
            : toast.error(result?.error?.data?.message);
        })
        .catch((error: any) => console.error('Error from mark notification as read: ', error));
    }

    // const request: any = await markAllNotificationAsRead(unreadNotificationGetData?.items || [])
    //     .then(async (result: any) => {
    //         console.log(result?.data?.path)

    //         result?.data?.path !== undefined ?
    //             await notificationGetDataRefetch() &&
    //             await unreadNotificationGetDataRefetch() :
    //             toast.error(result?.error?.data?.message)
    //     })
    //     .catch((error: any) => console.error("Error from mark notification as read: ", error))
  };

  const handleGetNotification = () => {
    setShowUnReadNotif(!showUnReadNotif);
    // setRefreshData(!refreshData);
  };

  const toggleNotifAlert = () => {
    return setPlaySound(playSound);
  };

  const timeAgo = (date) => {
    const seconds = Math.floor(((new Date() as any) - date) / 1000);
    const hours = Math.floor(seconds / 3600);
    const days = Math.floor(seconds / 86400);
    const weeks = Math.floor(seconds / (86400 * 7));

    if (seconds < 10) {
      return 'now';
    } else if (seconds < 90) {
      return 'about a minute ago';
    } else if (seconds < 3600) {
      return `${Math.floor(seconds / 60)}m`;
    } else if (seconds < 86400) {
      return `${hours}h`;
    } else if (days < 2) {
      return 'yesterday';
    } else if (days < 7) {
      return `${days}d`;
    } else {
      return `${weeks}w`;
    }
  };

  // const getNotification = async () => {

  //     // if (showUnReadNotif) {
  //     //     const result = await fetchUnreadNotif();
  //     //     setNotificationList(result?.items);
  //     //     setCountNotif(result?.items.length);
  //     //     // console.log(result?.items);
  //     // } else {
  //     //     const result = await fetchNotif();
  //     //     setNotificationList(result?.items);
  //     //     setCountNotif(result?.items.length);
  //     //     // console.log(result?.items);
  //     // }
  // }

  // const handleNotifListen = async () => {
  //     // pb.collection('notification').subscribe('*', function (e) {
  //     //     setRefreshData(!refreshData);
  //     // });

  // }

  // const handleReadNotification = async (note) => {
  //     // if (!note?.readStatus) {
  //     //     const res = await readNotif(note);
  //     //     history.push(`/hospital${note?.path}`);
  //     // }
  //     // else {
  //     //     history.push(`/hospital${note?.path}`);
  //     // }
  // }

  // const handleReadAllNotification = async () => {
  //     // try {
  //     //     readAllNotif(notificationList);

  //     // } catch (error) {
  //     //     console.log(error);
  //     // }
  // }

  useEffect(() => {
    // handleNotifListen();
    // getNotification();
    // getUserNotifications();
    pushPass();
    logOutOnRefresh();
    setPlaySound(!playSound);
  }, [refreshData, showUnReadNotif]);

  const logOutOnRefresh = () => {
    if (changed) {
      handleLogout();
    } else return;
  };

  const updateUserPass = async () => {
    const res = await api.post(
      'auth/resetPassFromProfile',
      {
        oldPassword: oldPass,
        newPassword: password,
      },
      token
    );
    // console.log(res)
    if (res?.data?.success) {
      setEditPass(false);
      setChanged(true);
    } else if (res?.data?.statusCode === 400) {
      toast.error('Old password is wrong');
    }
  };

  const handleLogout = () => {
    dispatch(loguserOut());
    localStorage.clear();
    dispatch(timeoutFunc({ payload: false }));
    //  window.location.reload();
    window.scrollTo(0, 0);
    history.push('/hospital/login');
  };

  const handleFormSubmit = () => {
    setUserPass({ oldPassword: oldPass, newPassword: password });
    setRefreshData(!refreshData);
  };

  const userToken = localStorage.getItem('token');

  const handleTimezoneEdit = () => {
    setIsSubmittingTimezone(true);

    axios
      .patch(
        `${REACT_APP_BASEURL}hospitals`,
        {
          id: JSON.parse(hospital || '')?.hospitalId || '',
          timeZoneLabel: timezoneInput,
          timeZoneOffset: TIMEZONES.find((zone) => zone.label === timezoneInput)?.utc || '',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            Authorization: `Bearer ${userToken}`,
          },
        }
      )
      .then((res) => res.data)
      .then((data) => {
        toast.success('Timezone updated');

        setIsEditTimezoneModalOpen(false);
        refetchHospitalDetails();
      })
      .catch((error) => {
        console.log(error);

        toast.error(error?.response?.data?.message || 'Error while updating timezone');
      })
      .finally(() => {
        setIsSubmittingTimezone(false);
      });
  };

  return (
    <div className="bg-light">
      <header className="navbar navbar-primary d-flex  flex-nowrap  bg-light sticky-top  px-1 shadow">
        <Col className="d-flex gap-2 p-3">
          <button
            style={{ zIndex: 9 }}
            onClick={() => toggleSideMenu()}
            className="navbar-toggler ml-3 shadow-none text-primary position-relative"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu"
            aria-controls="sidebarMenu"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-align-left" />
          </button>

          <button onClick={() => handleGoBack()} className="btn btn-outline-secondary ms-3">
            Go back
					</button>
        </Col>

        <Col className="d-flex justify-content-end px-5">
          <div className="navbar-nav text-right">
            {/* {playSound ? <audio src={civisAlert} autoPlay /> : ''} */}
            <div className="nav-item d-flex text-nowrap align-items-center">
              <div className="d-flex align-items-center">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => setIsEditTimezoneModalOpen(true)}
                  style={{ cursor: 'pointer' }}
                >
                  <path
                    d="M7 7H6C5.46957 7 4.96086 7.21071 4.58579 7.58579C4.21071 7.96086 4 8.46957 4 9V18C4 18.5304 4.21071 19.0391 4.58579 19.4142C4.96086 19.7893 5.46957 20 6 20H15C15.5304 20 16.0391 19.7893 16.4142 19.4142C16.7893 19.0391 17 18.5304 17 18V17"
                    stroke="#08283B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16 5.00011L19 8.00011M20.385 6.58511C20.7788 6.19126 21.0001 5.65709 21.0001 5.10011C21.0001 4.54312 20.7788 4.00895 20.385 3.61511C19.9912 3.22126 19.457 3 18.9 3C18.343 3 17.8088 3.22126 17.415 3.61511L9 12.0001V15.0001H12L20.385 6.58511Z"
                    stroke="#08283B"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <span className="ml-1">{USER_TIMEZONE?.label ? `(UTC${USER_TIMEZONE?.utc}) ${USER_TIMEZONE?.tzCode}` : 'No timezone set'}</span>
              </div>
              <ul className="d-flex m-0 p-0" style={{ listStyle: 'none' }}>
                {/* <li
                  onClick={() => {
                    history.push("/hospital/queue-management");
                  }}
                  className="text-primary px-3"
                  style={{ cursor: "pointer" }}
                >
                  <i className="fa fa-users fa-1x" aria-hidden="true"></i>
                </li>
                <li className="text-primary px-3 ">
                  <i className="fas fa-phone-slash fa-1x"></i>
                </li>*/}
                <li className="text-primary px-3 table-icon">
                  <div className="d-flex align-items-center">
                    {unreadNotificationGetData?.totalItems !== undefined && unreadNotificationGetData?.totalItems < 1 ? (
                      <img src={bellOff} height="20em" />
                    ) : (
                        <img src={bellOn} height="20em" />
                      )}
                    <p style={{ marginLeft: '2px' }}>{unreadNotificationGetData?.totalItems}</p>
                  </div>

                  <div className="content p-0 card border shadow  py-3 position-absolute" style={{ minWidth: '30em', maxWidth: '30em' }}>
                    <div className={`${styles.section} p-2 text-light text-start w-75`}>
                      <h5>Notifications</h5>
                    </div>

                    <Row className="mt-3">
                      <Col
                        // className="d-flex justify-content-start align-items-center p-0 m-0 gap-2 px-3"
                        onClick={() => {
                          handleGetNotification();
                        }}
                      >
                        <div className="d-flex justify-content-between">
                          {!showUnReadNotif ? (
                            <img
                              style={{ cursor: 'pointer' }}
                              // onClick={() => handleGetNotification()}
                              src={offIcon}
                              height="10em"
                              className="mt-1 ml-1"
                              alt=""
                            />
                          ) : (
                              <img
                                style={{ cursor: 'pointer' }}
                                // onClick={() => handleGetNotification()}
                                src={onIcon}
                                height="10em"
                                className="mt-1 ml-1"
                                alt=""
                              />
                            )}
                          <small
                            // onClick={() => handleGetNotification()}
                            className="px-1"
                            style={{ cursor: 'pointer' }}
                          >
                            Show only unread notifications
													</small>
                        </div>
                      </Col>

                      <Col
                      // className="d-flex gap-2 justify-content-end"
                      >
                        <div className="d-flex justify-content-between">
                          <i
                            onClick={() => setMuteAllSound(!muteAllSound)}
                            className={`${muteAllSound ? 'bi bi-volume-up' : 'bi bi-volume-mute'} mr-2`}
                            style={{ cursor: 'pointer' }}
                          />
                          <small
                            onClick={() => handleMarkAllAsReadNotification()}
                            className="p-0 mr-1"
                            style={{
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            }}
                          >
                            Mark all as Read
													</small>
                        </div>
                      </Col>
                    </Row>

                    <div className="p-3 my-2" style={{ overflowY: 'auto', overflowX: 'auto', maxHeight: '50vh' }}>
                      {notificationList.length < 1 ? (
                        <p className="text-center mt-4">No Notifications</p>
                      ) : (
                          notificationList.map((note, index) => (
                            <Row
                              key={index}
                              onClick={() => handleMarkAsReadNotification(note)}
                              className="mb-3"
                              style={{
                                cursor: 'pointer',
                                border: `${note?.readStatus ? '1px' : '3px'} solid #0f4e71`,
                                borderRadius: '5px',
                              }}
                            >
                              <Col>
                                <div className="d-flex justify-content-between">
                                  <h5
                                    className="text-capitalize mt-2 mb-0 p-0"
                                    style={{
                                      fontWeight: note?.readStatus ? 'normal' : 'bold',
                                    }}
                                  >
                                    {note.title}
                                  </h5>
                                  <p className="mt-2 mb-0 p-0">{timeAgo(new Date(note?.created))}</p>
                                </div>
                                <p
                                  className="text-left mb-2"
                                  style={{
                                    whiteSpace: 'pre-wrap',
                                    overflowWrap: 'break-word',
                                    fontWeight: note?.readStatus ? 'normal' : 'bold',
                                  }}
                                >
                                  {note?.content}
                                </p>
                              </Col>
                            </Row>
                          ))
                        )}
                    </div>
                  </div>
                </li>
              </ul>

              <svg width="3" height="42" viewBox="0 0 3 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                <line x1="1.5" y1="1.5" x2="1.5" y2="40.5" stroke="#9CA9B0" stroke-width="3" stroke-linecap="round" />
              </svg>

              <span className=" text-primary fw-bold d-none px-3 d-md-flex flex-column align-items-center">
                <p className="pb-0 mb-0 text-center text-capitalize">{val?.name ? val?.name : ''}</p>
                <p className="mt-0 mb-0 pt-0 text-center">{val?.civisHospitalId ? val?.civisHospitalId : ''}</p>
              </span>

              <div className="ml-2 d-flex align-items-center justify-content-center">
                <div className="table-icon text-primary">
                  {token == '' ? '' : <i className="bi bi-person-circle" style={{ fontSize: '1.5em' }}></i>}
                  <div className="content p-2 card border shadow position-absolute mr-2">
                    <Card className="rounded rounded-3 border-0 shadow-lg text-left" style={{ minWidth: '10rem' }}>
                      {
                        <>
                          <ListGroup variant="flush">
                            <ListGroup.Item
                              onClick={() => {
                                history.push('/hospital/profile');
                                window.scrollTo(0, 0);
                              }}
                            >
                              Profile
														</ListGroup.Item>
                          </ListGroup>

                          <ListGroup variant="flush">
                            <ListGroup.Item
                              onClick={() => {
                                setEditPass(true);
                                setCountNow(true);
                              }}
                            >
                              Change passwords
														</ListGroup.Item>
                          </ListGroup>

                          <ListGroup variant="flush">
                            <ListGroup.Item onClick={handleLogout}>Log out</ListGroup.Item>
                          </ListGroup>
                        </>
                      }
                    </Card>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </header>

      {changed ? <PasswordChangedSuccess showModal={changed} exitModal={() => handleExitSucModal()} /> : ''}

      <Modal
        style={{ zIndex: '999999999999999' }}
        show={editPass}
        centered
        onHide={() => {
          setEditPass(false);
          setPassword('');
          setPasswordAgain('');
        }}
      >
        <Modal.Header className="modal-header border-0" closeButton></Modal.Header>
        <Modal.Body className="text-center">
          <EditPassword
            setOldPass={setOldPass}
            oldPassword={oldPass}
            setPassword={setPassword}
            setPasswordAgain={setPasswordAgain}
            passwordValue={password}
            passwordAgain={passwordAgain}
            passValidation={passValidation}
            setPassValidation={setPassValidation}
          />
          <Button
            variant="outline-primary rounded"
            className="mt-5 mb-2 px-5"
            disabled={passValidation == false || oldPass == ''}
            onClick={() => handleFormSubmit()}
          >
            Submit
					</Button>
        </Modal.Body>
      </Modal>

      <Modal
        show={isInvoiceNewPage}
        centered
        onHide={() => {
          setIsInvoiceNewPage(false);
        }}
      >
        <>
          <Modal.Header className="modal-header border-0  text-white" closeButton></Modal.Header>
          <Modal.Body className="text-center mb-4">Are you sure you want to leave this page, your current changes may not be saved.</Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <ButtonGroup>
              <Button
                variant="primary"
                className="mr-2"
                onClick={() => {
                  history.goBack();
                  setIsInvoiceNewPage(false);
                }}
              >
                Go Back
							</Button>
              <Button
                variant="outline-primary"
                onClick={() => {
                  setIsInvoiceNewPage(false);
                }}
              >
                Continue Editing
							</Button>
            </ButtonGroup>
          </Modal.Footer>
        </>
      </Modal>

      <Modal centered show={isEditTimezoneModalOpen} onHide={() => setIsEditTimezoneModalOpen(false)}>
        <Modal.Header closeButton className="bg-primary text-white rounded-1" closeVariant="white">
          <Modal.Title>Edit Timezone</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-2">
          {/* <small>Reason for cancellation</small>
              <Input
                as="textarea"
                onChange={(v, e) => {
                  setCancelReason((prev: any) => v);
                }}
              /> */}

          <InputPicker
            size="lg"
            name="timeZoneLabel"
            data={TIMEZONES || []}
            labelKey="label"
            valueKey="label"
            placeholder="Select Time Zone"
            className="w-100"
            defaultValue={hospitalDetails?.payload?.timeZoneLabel || ''}
            onChange={(value, event) => {
              setTimezoneInput(value);
            }}
            menuStyle={{ zIndex: 20000 }}
          />
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button className="text-center mr-2" variant="primary" onClick={handleTimezoneEdit} disabled={isSubmittingTimezone}>
            {isSubmittingTimezone ? 'Loading' : 'Proceed'}
          </Button>
          <Button
            className="text-center border text-primary border-primary"
            variant="outline"
            onClick={() => {
              setIsEditTimezoneModalOpen(false);
            }}
          >
            Cancel
					</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TopNavTS;
