import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import civisLogo from '../assets/images/civislogoonly.png';
import writeXlsxFile from 'write-excel-file';

export const useExportToPDF = () => {
	const convertToPDF = (args) => {
		const { fileName, tableData, headerRow } = args;
		const doc = new jsPDF('l', 'mm', 'a4');

		doc.addImage(civisLogo, 'JPEG', 14, 5, 0, 20);
		doc.text([fileName], 40, 16);

		autoTable(doc, {
			startY: 30,
			head: [headerRow],
			body: tableData.map((item) => item.map((subItem) => [subItem])),
			headStyles: { fillColor: [20, 79, 112] },
			didDrawCell: (data) => {},
		});

		doc.save(`${fileName}.pdf`);
	};

	return convertToPDF;
};
export const useExportToXlsx = () => {
	const convertToXlsx = async (args) => {
		const { fileName, headerRow, tableData } = args;

		await writeXlsxFile([headerRow, ...tableData], {
			fileName,
		});
	};

	return convertToXlsx;
};
