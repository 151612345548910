import { createApi } from '@reduxjs/toolkit/query/react';
import { HcpcsCodeType, IServiceType } from '../../../../interfaces/billableItems';
import { IApiResponse, IPaginate } from '../../../../interfaces/common';
import { baseQuery } from '../../common';

export const serviceTypeApi = createApi({
	reducerPath: 'serviceTypes',
	baseQuery: baseQuery,
	tagTypes: ['ServiceTypes', 'ServiceType'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		uploadBillableItemsFile: builder.mutation<IApiResponse<IServiceType[]>, { data: FormData; service?: string }>({
			query: ({ data, service = 'professional-services' }) => ({
				url: `/billable-items/upload?service=${service}`,
				method: 'POST',
				body: data,
			}),
		}),
		addServiceType: builder.mutation<IServiceType, Partial<IServiceType>>({
			query: (body) => ({
				url: `billable-items/service-types`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['ServiceTypes'],
		}),
		updateServiceType: builder.mutation<IServiceType, Partial<IServiceType>>({
			query(data) {
				return {
					url: `billable-items/service-types/${data.id}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'ServiceType', id: arg.id }, 'ServiceTypes'],
		}),
		getServiceTypeForProfessionalService: builder.query<
			IApiResponse<IPaginate<IServiceType[]>>,
			{ pagination: { page: number; limit: number } }
		>({
			query: ({ pagination: { page = 1, limit = 10 } }) =>
				`billable-items/service-types?service=professional-services&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ServiceTypes', id: 'LIST' }],
		}),
		getHcpcsCodes: builder.query<IApiResponse<HcpcsCodeType[]>, { pagination: { page: number; limit: number; keyword: string } }>({
			query: ({ pagination: { page = 1, limit = 10, keyword = '' } }) => `hcpcs-codes?page=${page}&limit=${limit}&keyword=${keyword}`,
			providesTags: [{ type: 'ServiceTypes', id: 'LIST' }],
		}),
		getAllServiceTypeForProfessionalService: builder.query<IApiResponse<IPaginate<IServiceType[]>>, void>({
			query: () => `billable-items/service-types?service=professional-services&limit=${'300'}`,
			providesTags: [{ type: 'ServiceTypes', id: 'LIST' }],
		}),
		getServiceTypeForOthers: builder.query<IApiResponse<IPaginate<IServiceType[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `billable-items/service-types?service=others&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'ServiceTypes', id: 'LIST' }],
		}),
		getAllServiceTypeForOthers: builder.query<IApiResponse<IPaginate<IServiceType[]>>, void>({
			query: () => `billable-items/service-types?service=others&limit=${'300'}`,
			providesTags: [{ type: 'ServiceTypes', id: 'LIST' }],
		}),
		deleteServiceType: builder.mutation<IServiceType, Partial<IServiceType>>({
			query: (data) => ({
				url: `billable-items/service-types/${data.id}`,
				method: 'DELETE',
				body: data,
			}),
			invalidatesTags: ['ServiceTypes'],
		}),
		setDefaultServiceTypes: builder.mutation<any, Partial<IServiceType>>({
			query(body) {
				return {
					url: `billable-items/service-types/setup`,
					method: 'POST',
					body,
				};
			},
		}),
	}),
});

export const {
	useUploadBillableItemsFileMutation,
	useGetServiceTypeForProfessionalServiceQuery,
	useAddServiceTypeMutation,
	useUpdateServiceTypeMutation,
	useGetServiceTypeForOthersQuery,
	useGetHcpcsCodesQuery,
	useGetAllServiceTypeForProfessionalServiceQuery,
	useDeleteServiceTypeMutation,
	useGetAllServiceTypeForOthersQuery,
	useSetDefaultServiceTypesMutation,
} = serviceTypeApi;
