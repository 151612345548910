import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { IVitals, IVitalsRequest } from '../../interfaces/vitals';
import { baseQuery } from './common';
import { IAdmissionSummary } from '../../interfaces/admission';
import { IAttachment } from '../../interfaces/attachment';
import { IMedicationSummary } from '../../interfaces/medication';
import { IAppointmentSummary } from '../../interfaces/appointment';
import { IDiagnosisSummary } from '../../interfaces/diagnosis';

export const vitalsApi = createApi({
	reducerPath: 'vitals',
	baseQuery: baseQuery,
	tagTypes: ['Vitals', 'Vital', 'VitalsByDate'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getPatientVitalsSummary: builder.query<IApiResponse<IVitals[]>, { id: string }>({
			query: ({ id }) => `/vitals/patient-summary/${id}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),
		getPatientAdmissionSummary: builder.query<IApiResponse<IAdmissionSummary[]>, { id: string }>({
			query: ({ id }) => `/patient-admissions/summary/${id}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),
		getPatientMedicationSummary: builder.query<IApiResponse<IMedicationSummary[]>, { id: string }>({
			query: ({ id }) => `/medication-request/dispensed-summary/${id}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),
		getPatientAppointmentSummary: builder.query<IApiResponse<IAppointmentSummary[]>, { id: string }>({
			query: ({ id }) => `/appointments/summary/${id}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),
		getTherapeuticsSummary: builder.query<IApiResponse<IPaginate<IVitals[]>>, { id: string }>({
			query: ({ id }) => `/therapeutic-administrations/cancelled/summary/${id}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),
		getDiagnosisSummary: builder.query<IApiResponse<IDiagnosisSummary[]>, { id: string }>({
			query: ({ id }) => `/consultation/diagnosis/summary/${id}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),

		getVitalsForPatient: builder.query<IApiResponse<IPaginate<IVitals[]>>, { pagination: { page: number; limit: number }; id: string }>({
			query: ({ id, pagination: { page = 1, limit = 10 } }) => `/vitals/patient/${id}?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Vitals', id: 'LIST' }],
		}),
		getVitalsByDate: builder.query<
			IApiResponse<IPaginate<IVitals[]>>,
			{ id: string; date: string; pagination: { page: number; limit: number } }
		>({
			query: ({ id, date, pagination: { page = 1, limit = 10 } }) => `/vitals/patient/${id}/date/${date}?page=${page}&limit=${limit}`,
			providesTags: (result, error, arg) => [{ type: 'VitalsByDate', id: `${arg.id}-${arg.date}` }],
		}),
		getVital: builder.query<IApiResponse<IPaginate<IVitals[]>>, string>({
			query: (id) => `/vitals/${id}`,
			providesTags: (result, error, arg) => [{ type: 'Vital', id: arg }],
		}),
		addVitals: builder.mutation<IVitalsRequest, Partial<IVitalsRequest>>({
			query: (body) => ({
				url: `vitals`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Vitals', 'VitalsByDate'],
		}),
		updateVitals: builder.mutation<IVitals, Partial<IVitals>>({
			query(data) {
				return {
					url: `vitals/${data.id}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Vital', id: arg.id }, 'Vitals', 'VitalsByDate'],
		}),

		addAttachment: builder.mutation<IAttachment, { patientId: string; body: any }>({
			query: ({ patientId, body }) => ({
				url: `/patients/${patientId}/attachments`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Vitals'],
		}),
		getAttachments: builder.query<IApiResponse<IPaginate<IAttachment[]>>, { pagination: { page: number; limit: number }; patientId: string }>(
			{
				query: ({ patientId, pagination: { page = 1, limit = 10 } }) => `patients/${patientId}/attachments?page=${page}&limit=${limit}`,
				providesTags: [{ type: 'Vitals', id: 'LIST' }],
			}
		),
		deleteAttachment: builder.mutation<IAttachment, Partial<IAttachment>>({
			query: ({ patientId, id }) => ({
				url: `/patients/${patientId}/attachments/${id}`,
				method: 'DELETE',
			}),
			invalidatesTags: ['Vitals'],
		}),
	}),
});

export const {
	useGetPatientVitalsSummaryQuery,
	useGetPatientAdmissionSummaryQuery,
	useGetPatientMedicationSummaryQuery,
	useGetPatientAppointmentSummaryQuery,
	useGetTherapeuticsSummaryQuery,
	useGetDiagnosisSummaryQuery,

	useGetVitalsForPatientQuery,
	useLazyGetVitalsByDateQuery,
	useGetVitalsByDateQuery,
	useAddVitalsMutation,
	useUpdateVitalsMutation,
	usePrefetch,

	useAddAttachmentMutation,
	useGetAttachmentsQuery,
	useDeleteAttachmentMutation,
} = vitalsApi;
