import { createApi } from '@reduxjs/toolkit/query/react';
import { ILabTest } from '../../../interfaces/lab';
import { IApiResponse, IPaginate } from '../../../interfaces/common';
import { baseQuery } from '../common';
import { IDiagnosisSearch } from '../../../interfaces/diagnosis';

export const labTestApi = createApi({
	reducerPath: 'labTests',
	baseQuery: baseQuery,
	tagTypes: ['LabTests', 'LabTest'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		uploadLabItemsFile: builder.mutation<IApiResponse<ILabTest[]>, FormData>({
			query: (body) => ({
				url: `/laboratory/test/upload`,
				method: 'POST',
				body,
			}),
		}),
		getLabTest: builder.query<IApiResponse<IPaginate<ILabTest[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `/laboratory/test?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'LabTests', id: 'LIST' }],
		}),
		searchICD: builder.query<
			IApiResponse<IPaginate<IDiagnosisSearch[]>>,
			{
				keyword: string;
				type: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ keyword = '', type, pagination: { page = 1, limit = 10 } }) =>
				`/icd-search/?keyword=${keyword}&type=${type}&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'LabTests', id: 'LIST' }],
		}),
		getLabForAnId: builder.query<IApiResponse<ILabTest>, string>({
			query: (id) => `/laboratory/test/${id}`,
			//   keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'LabTest', id: arg }],
		}),
		addLabTest: builder.mutation<ILabTest, Partial<ILabTest>>({
			query: (body) => ({
				url: `/laboratory/test`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['LabTests', 'LabTest'],
		}),
		setDefaultTest: builder.mutation<any, Partial<ILabTest>>({
			query(body) {
				return {
					url: `/laboratory/test/setup`,
					method: 'POST',
					body,
				};
			},
			invalidatesTags: ['LabTests', 'LabTest'],
		}),
		searchTest: builder.query<
			IApiResponse<IPaginate<ILabTest[]>>,
			{
				name?: string;
				category?: string;
				categoryId?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ name, category, categoryId, pagination: { page = 1, limit = 10 } }) =>
				`/laboratory/test/filter?page=${page}&limit=${limit}${name && `&name=${name}`}`,
			providesTags: [{ type: 'LabTests' }],
		}),
		updateLabTest: builder.mutation<ILabTest, Partial<ILabTest>>({
			query(data) {
				return {
					url: `/laboratory/test/${data.id}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'LabTest', id: arg.id }, 'LabTests'],
		}),
		deleteLabTest: builder.mutation<ILabTest, Partial<ILabTest>>({
			query: ({ id }) => ({
				url: `/laboratory/test/${id}`,
				method: 'DELETE',
				body: id,
			}),
			invalidatesTags: ['LabTests', 'LabTest'],
		}),
	}),
});

export const {
	useUploadLabItemsFileMutation,
	useGetLabTestQuery,
	useGetLabForAnIdQuery,
	useSearchICDQuery,
	useAddLabTestMutation,
	useUpdateLabTestMutation,
	useSetDefaultTestMutation,
	useDeleteLabTestMutation,
	usePrefetch,
} = labTestApi;
