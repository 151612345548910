import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

export interface ILineOneComponent {
	name: string;
	count: number;
	icon?: string;
	url: string;
}

const Box: React.FC<any> = ({ data }) => (
	<Row
		className="shadow border rounded-3 px-4 py-4 mx-1 my-2"
		// style={{ maxWidth: "30rem", minWidth: "10rem" }}
	>
		<Col xs={7} className="d-flex flex-column">
			<p className="fw-bold text-primary" style={{ fontSize: '0.9rem' }}>
				{data?.count}
			</p>
			<small className="mb-2">{data?.name}</small>
		</Col>
		{data?.icon ? (
			<Col className="d-flex justify-content-end">
				<img src={data?.icon} alt="icon" height={40} />
			</Col>
		) : null}
	</Row>
);

const LineOneComponent: React.FC<ILineOneComponent> = (props) => {
	const history = useHistory();
	return (
		<Col style={{ cursor: 'pointer' }} onClick={() => history.push(props.url)} key={props.name} xs={3}>
			<Box data={props} />
		</Col>
	);
};

export default LineOneComponent;
