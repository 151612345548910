import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse } from '../../interfaces/common';
import { baseQuery } from './common';
import { IHospital } from '../../interfaces/hospital';

export const hospitalApi = createApi({
	reducerPath: 'hospital',
	baseQuery: baseQuery,
	tagTypes: ['Hospital'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getHospitalDetails: builder.query<IApiResponse<IHospital>, string>({
			query: (id) => `hospitals/${id}`,
			providesTags: (result, error, arg) => [{ type: 'Hospital', id: arg }],
		}),
	}),
});

export const { useGetHospitalDetailsQuery } = hospitalApi;
