export const APP_URLS = {
	dashboard: {
		get_users: 'dashboard/getUsers',
		get_patients: 'dashboard/getPatients',
		get_lab_and_rad: 'dashboard/getLabAndRad',
		get_medication_request: 'dashboard/getMedicationRequest',
		get_products: 'dashboard/getProducts',
		get_orders: 'dashboard/getOrders',
		get_labs: 'dashboard/getLabs',
		get_radiology: 'dashboard/getRadiology',
		get_patient_admissions: 'dashboard/getPatientAdmissions',
		get_vendors: 'dashboard/getVendors',
		get_appointment: 'dashboard/getAppointments/{userId}',
		get_blood_bank: 'dashboard/getBloodBank',
		get_invoice: 'dashboard/getInvoice',
		get_departments: 'dashboard/getDepartments',
	},
};
