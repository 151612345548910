import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { IApiResponse, IPaginate } from "../../../interfaces/common";
import { IMedicationRequest } from "../../../interfaces/medication";
import { IOrder } from "../../../pages/HospitalAdmin/Medication/medicationRequest";
import { IPresc } from "../../../pages/HospitalAdmin/patients/PatientView/Consultation/Prescription/newPrescription";
import { baseQuery } from "../common";

export const medicationRequestApi = createApi({
  reducerPath: "medication",
  baseQuery: baseQuery,
  tagTypes: ["Medications", "Medication"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getMedicationRequests: builder.query<
      IApiResponse<IPaginate<IMedicationRequest[]>>,
      {
        pagination: { page: number; limit: number };
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `/medication-request/filter?status[]=unattended&status[]=in-progress&filterOperator=and&page=${page}&limit=${limit}`,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),
    getMedicationRequest: builder.query<
      IApiResponse<IPaginate<IMedicationRequest[]>>,
      string
    >({
      query: (id) => `/medication-request/${id}`,
      providesTags: (result, error, arg) => [{ type: "Medication", id: arg }],
    }),
    getMedicationRequestforPatient: builder.query<
      IApiResponse<IPaginate<IMedicationRequest[]>>,
      {
        pagination: { page: number; limit: number };
        patientId: string;
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 }, patientId }) =>
        `medication-request/filter?patientId=${patientId}&page=${page}&limit=${limit}
      `,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),
    medicationRequestFilter: builder.query<
      IApiResponse<IPaginate<IMedicationRequest[]>>,
      {
        pagination: { page?: number; limit?: number };
        searchTerm?: string;
        status?: string;
        patientId?: string;
        filterDate?: string;
      }
    >({
      query: ({
        pagination: { page = 1, limit = 10 },
        searchTerm,
        status,
        patientId,
        filterDate,
      }) =>
        `medication-request/filter/${patientId}?page=${page}&limit=${limit}${
          status !== undefined ? `&status=${status}` : ``
        }${searchTerm !== undefined ? `&name=${searchTerm}` : ``}${
          filterDate !== undefined ? `&date=${filterDate}` : ``
        }
        
      `,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),

    createMedication: builder.mutation<
      IMedicationRequest,
      Partial<IMedicationRequest>
    >({
      query: (body) => ({
        url: `medication-request`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    editMedication: builder.mutation<
      IMedicationRequest,
      Partial<IMedicationRequest>
    >({
      query: (body) => ({
        url: `medication-request/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    cancelMedication: builder.mutation<
      IMedicationRequest,
      Partial<IMedicationRequest>
    >({
      query: (body) => ({
        url: `medication-request/${body.id}/cancel`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    createMedicationOrder: builder.mutation<
      IMedicationRequest,
      Partial<IOrder>
    >({
      query: (body) => ({
        url: `orders`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    createMultipleMedicationOrder: builder.mutation<
      IMedicationRequest,
      Partial<IOrder>
    >({
      query: (body) => ({
        url: `orders`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    getOrderForSavedAndSubmittedStatus: builder.query<
      IApiResponse<IPaginate<Array<string | number | boolean>>>,
      {
        pagination: { page: number; limit: number };
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `orders/filter?status[]=draft&status[]=submitted&page=${page}&limit=${limit}`,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),

    getMedicationOrder: builder.query<
      IApiResponse<IPaginate<IMedicationRequest[]>>,
      { pagination: { page: number; limit: number } }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `orders?page=${page}&limit=${limit}`,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),

    getMedicationOrderCompletedWithDateFilter: builder.query<
      IApiResponse<IPaginate<IMedicationRequest[]>>,
      {
        pagination: { page: number; limit: number };
        filterDateStart?: string;
        filterDateEnd?: string;
      }
    >({
      query: ({
        pagination: { page = 1, limit = 10 },
        filterDateStart,
        filterDateEnd,
      }) =>
        `orders/filter?page=${page}&limit=${limit}&status[]=completed${
          filterDateStart && `&startDate=${filterDateStart}`
        }${filterDateEnd && `&endDate=${filterDateEnd}`}&filterOperator=and`,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),

    getMedicationOrderForAnId: builder.query<
      IApiResponse<IMedicationRequest>,
      string
    >({
      query: (id) => `orders/${id}`,
      providesTags: (result, error, arg) => [{ type: "Medications", id: arg }],
    }),

    submitMedicationOrder: builder.mutation<IMedicationRequest, string>({
      query: (body) => ({
        url: `orders/${body}/submit`,
        method: "PATCH",
      }),
      invalidatesTags: ["Medications"],
    }),

    cancelMedicationOrder: builder.mutation<IMedicationRequest, string>({
      query: (body) => ({
        url: `orders/${body}/cancel`,
        method: "PATCH",
      }),
      invalidatesTags: ["Medications"],
    }),

    dispenseMedicationOrder: builder.mutation<IMedicationRequest, string>({
      query: (body) => ({
        url: `orders/${body}/dispense`,
        method: "PATCH",
      }),
      invalidatesTags: ["Medications"],
    }),

    deleteMedicationOrder: builder.mutation<IMedicationRequest, string>({
      query: (body) => ({
        url: `orders/${body}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Medications"],
    }),

    editMedicationOrder: builder.mutation<
      IMedicationRequest,
      {
        [key: string]: any;
      }
    >({
      query: (body) => ({
        url: `orders/${body.id}`,
        method: "PATCH",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    createMedicationFromPatientModule: builder.mutation<
      IPresc,
      Partial<IPresc>
    >({
      query: (body) => ({
        url: `consultation/${body.id}/prescriptions`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    editMedicationFromPatientModule: builder.mutation<IPresc, Partial<IPresc>>({
      query: (body) => ({
        url: `consultation/${body.id}/prescription/${body.prescriptionId}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: ["Medications"],
    }),

    getInprogressTherapuetic: builder.query<
      IApiResponse<IPaginate<Array<string | number | boolean>>>,
      {
        pagination: { page: number; limit: number };
      }
    >({
      query: ({ pagination: { page = 1, limit = 10 } }) =>
        `therapeutic-administrations/filter?status[]=in-progress&page=${page}&limit=${limit}`,
      providesTags: [{ type: "Medications", id: "LIST" }],
    }),
  }),
});

export const {
  useGetMedicationRequestsQuery,
  useGetMedicationRequestQuery,
  useGetMedicationRequestforPatientQuery,
  useMedicationRequestFilterQuery,
  useCreateMedicationMutation,
  useEditMedicationMutation,
  useCancelMedicationMutation,
  useCreateMedicationOrderMutation,
  useCreateMultipleMedicationOrderMutation,
  useGetOrderForSavedAndSubmittedStatusQuery,
  useGetMedicationOrderForAnIdQuery,
  useSubmitMedicationOrderMutation,
  useCancelMedicationOrderMutation,
  useDispenseMedicationOrderMutation,
  useDeleteMedicationOrderMutation,
  useEditMedicationOrderMutation,
  useCreateMedicationFromPatientModuleMutation,
  useEditMedicationFromPatientModuleMutation,
  useGetMedicationOrderQuery,
  useGetMedicationOrderCompletedWithDateFilterQuery,
  useGetInprogressTherapueticQuery,
} = medicationRequestApi;
