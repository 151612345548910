import { createApi } from '@reduxjs/toolkit/query/react';
import { IBilliableItem } from '../../../../interfaces/billableItems';
import { IApiResponse, IPaginate } from '../../../../interfaces/common';
import { baseQuery } from '../../common';

export const itemApi = createApi({
	reducerPath: 'items',
	baseQuery: baseQuery,
	tagTypes: ['Items', 'Item'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		addItem: builder.mutation<IBilliableItem, Partial<IBilliableItem>>({
			query: (body) => ({
				url: `billable-items`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Items'],
		}),
		updateItem: builder.mutation<IBilliableItem, Partial<IBilliableItem>>({
			query(data) {
				return {
					url: `billable-items/${data.id}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Item', id: arg.id }, 'Items'],
		}),
		getProfessionalServiceItem: builder.query<IApiResponse<IBilliableItem>, { id: string }>({
			query: ({ id }) => `billable-items/${id}`,
			providesTags: (result, error, arg) => [{ type: 'Item', id: arg.id }],
		}),
		getItemForProfessionalService: builder.query<IApiResponse<IPaginate<IBilliableItem[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `billable-items?service=professional-services&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Items', id: 'LIST' }],
		}),
		getAllItemForProfessionalService: builder.query<IApiResponse<IPaginate<IBilliableItem[]>>, void>({
			query: () => `billable-items?service=professional-services&limit=${'300'}`,
			providesTags: [{ type: 'Items', id: 'LIST' }],
		}),
		getItemForOthers: builder.query<IApiResponse<IPaginate<IBilliableItem[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `billable-items?service=others&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Items', id: 'LIST' }],
		}),
		getAllItemForOthers: builder.query<IApiResponse<IPaginate<IBilliableItem[]>>, void>({
			query: () => `billable-items?service=others&limit=${'300'}`,
			providesTags: [{ type: 'Items', id: 'LIST' }],
		}),
		deleteItem: builder.mutation<IBilliableItem, Partial<IBilliableItem>>({
			query: (data) => ({
				url: `billable-items/${data.id}`,
				method: 'DELETE',
				body: data,
			}),
			invalidatesTags: ['Items'],
		}),
		setDefaultItems: builder.mutation<any, Partial<IBilliableItem>>({
			query(body) {
				return {
					url: `billable-items/setup`,
					method: 'POST',
					body,
				};
			},
		}),
	}),
});

export const {
	useAddItemMutation,
	useUpdateItemMutation,
	useGetProfessionalServiceItemQuery,
	useGetItemForProfessionalServiceQuery,
	useGetItemForOthersQuery,
	useDeleteItemMutation,
	useSetDefaultItemsMutation,
	useGetAllItemForProfessionalServiceQuery,
	useGetAllItemForOthersQuery,
} = itemApi;
