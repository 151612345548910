import { createApi } from "@reduxjs/toolkit/query/react";
import { IAppointment, IPatientCheckIn } from "../../../interfaces/appointment";
import { IApiResponse } from "../../../interfaces/common";
import { baseQuery } from "../common";

export const appointmentApi = createApi({
  reducerPath: "appointment",
  baseQuery: baseQuery,
  tagTypes: ["Appointment"],
  refetchOnFocus: true,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
  endpoints: (builder) => ({
    getAppointment: builder.query<IApiResponse<IAppointment[]>, string>({
      query: (id) => `/appointments/${id}`,
      providesTags: (result, error, arg) => [{ type: "Appointment", id: arg }],
    }),
    getAppointmentForAnId: builder.query<IApiResponse<IAppointment>, string>({
      query: (id) => `/appointments/single/${id}`,
      providesTags: (result, error, arg) => [{ type: "Appointment", id: arg }],
    }),
    addAppointment: builder.mutation<IAppointment, Partial<IAppointment>>({
      query: (body) => ({
        url: `/appointments/create-internal`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Appointment"],
    }),
    addAppointmentExternal: builder.mutation<
      IAppointment,
      Partial<IAppointment>
    >({
      query: (body) => ({
        url: `/appointments/create-external`,
        method: "POST",
        body,
      }),
      invalidatesTags: ["Appointment"],
    }),
    updateAppointment: builder.mutation<IAppointment, Partial<IAppointment>>({
      query(data) {
        return {
          url: `/appointments/${data.id}`,
          method: "PATCH",
          body: data,
        };
      },
      invalidatesTags: ["Appointment"],
    }),
    filterByAppointmentType: builder.query<
      IApiResponse<IAppointment[]>,
      { appType?: string; patName?: string; consName?: string; patId?: string; }
    >({
      query: ({ appType, patName, consName, patId }) =>
        `/appointments/filter?appointmentType=${appType}${
          patName && `&patientName=${patName}`
        }${consName && `&consultantName=${consName}`}${patId && `&patientId=${patId}`}`,
      providesTags: [{ type: "Appointment", id: "LIST" }],
    }),
    cancelAppointment: builder.mutation<IAppointment, Partial<IAppointment>>({
      query(data) {
        return {
          url: `/appointments/cancel/${data.id}`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Appointment"],
    }),
    checkInPatientForAppointment: builder.mutation<
      IPatientCheckIn,
      Partial<IAppointment>
    >({
      query(data) {
        return {
          url: `/appointments/checkin`,
          method: "POST",
          body: data,
        };
      },
      invalidatesTags: ["Appointment"],
    }),
  }),
});

export const {
  useGetAppointmentQuery,
  useAddAppointmentMutation,
  useAddAppointmentExternalMutation,
  useGetAppointmentForAnIdQuery,
  useUpdateAppointmentMutation,
  useFilterByAppointmentTypeQuery,
  useCancelAppointmentMutation,
  useCheckInPatientForAppointmentMutation,
} = appointmentApi;
