import { createApi } from '@reduxjs/toolkit/query/react';
import { number, string } from 'yargs';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { IProduct } from '../../interfaces/products';
import { baseQuery } from './common';

export const productsApi = createApi({
	reducerPath: 'products',
	baseQuery: baseQuery,
	tagTypes: ['Template', 'Products'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getProductExcelTemplate: builder.query<IApiResponse<{ url: string }>, 'url' | 'file'>({
			query: (type: 'url' | 'file' = 'url') => `/Product/template/?type=${type}`,
			providesTags: (result, error, arg) => [{ type: 'Template', id: arg }],
		}),
		uploadExcel: builder.mutation<IApiResponse<IProduct[]>, FormData>({
			query: (body) => ({
				url: `/Product/upload`,
				method: 'POST',
				body,
			}),
		}),
		getProducts: builder.query<IApiResponse<IPaginate<IProduct[]>>, { pagination: { page: number; limit: number } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `/Product?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Products', id: 'LIST' }],
		}),
		getSearchProduct: builder.query<
			IApiResponse<IPaginate<IProduct[]>>,
			{
				searchString?: string;
				type?: string;
				status?: string;
				category?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ searchString, type, status, category, pagination: { page = 1, limit = 10 } }) =>
				`/Product/filter?page=${page}&limit=${limit}${searchString && `&name=${searchString}`}&type=${type}&category=${category}${
					status ? `&status=${status}` : ''
				}`,
			providesTags: [{ type: 'Products', id: 'LIST' }],
		}),
	}),
});

export const { useGetProductExcelTemplateQuery, useUploadExcelMutation, useGetProductsQuery, useGetSearchProductQuery } = productsApi;
