import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../../common';
import {
	IInvoice,
	IInvoiceAddPayment,
	IInvoiceCancellation,
	IInvoiceExternalPatient,
	IInvoiceItems,
	IInvoicePayload,
	IInvoiceWriteOff,
} from '../../../../interfaces/invoice';
import { IApiResponse, IPaginate } from '../../../../interfaces/common';

export const invoiceApi = createApi({
	reducerPath: 'invoices',
	baseQuery: baseQuery,
	tagTypes: ['Invoices', 'Invoice'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		createBlankInvoice: builder.mutation<IInvoice, Partial<IInvoice>>({
			query: (body) => ({
				url: `invoice`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Invoices'],
		}),
		createBlankInvoiceExternal: builder.mutation<IInvoice, Partial<IInvoiceExternalPatient>>({
			query: (body) => ({
				url: `invoice/external`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Invoices'],
		}),
		addItemToInvoice: builder.mutation<IInvoiceItems, Partial<IInvoiceItems>>({
			query: (body) => ({
				url: `invoice/${body.id}/items`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoices'],
		}),
		getInvoice: builder.query<IApiResponse<IInvoicePayload>, void>({
			query: () => `invoice?status=draft`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		getInvoiceDraft: builder.query<
			IApiResponse<IInvoicePayload>,
			{
				pagination: { page: number; limit: number };
				searchTerm?: string;
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm, filterDateStart, filterDateEnd }) =>
				`invoice?page=${page}&limit=${limit}&status=draft${searchTerm ? `&searchTerm=${searchTerm}` : ''}${
					filterDateStart ? `&startDate=${filterDateStart}` : ''
				}${filterDateEnd ? `&endDate=${filterDateEnd}` : ''}`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		getInvoiceBilled: builder.query<
			IApiResponse<IInvoicePayload>,
			{
				pagination: { page: number; limit: number };
				searchTerm?: string;
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm, filterDateStart, filterDateEnd }) =>
				`invoice?page=${page}&limit=${limit}&status=billed${searchTerm && `&searchTerm=${searchTerm}`}${
					filterDateStart && `&startDate=${filterDateStart}`
				}${filterDateEnd && `&endDate=${filterDateEnd}`}`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		getInvoicePaid: builder.query<
			IApiResponse<IInvoicePayload>,
			{
				pagination: { page: number; limit: number };
				searchTerm?: string;
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm, filterDateStart, filterDateEnd }) =>
				`invoice?page=${page}&limit=${limit}&status=paid${searchTerm && `&searchTerm=${searchTerm}`}${
					filterDateStart && `&startDate=${filterDateStart}`
				}${filterDateEnd && `&endDate=${filterDateEnd}`}`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		getInvoiceById: builder.query<IApiResponse<IInvoice>, string>({
			query: (id) => `invoice/${id}`,
			keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'Invoice', id: arg }],
		}),
		getInvoiceCancelled: builder.query<
			IApiResponse<IInvoicePayload>,
			{
				pagination: { page: number; limit: number };
				searchTerm?: string;
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm, filterDateStart, filterDateEnd }) =>
				`invoice?page=${page}&limit=${limit}&status=cancelled${searchTerm && `&searchTerm=${searchTerm}`}${
					filterDateStart && `&startDate=${filterDateStart}`
				}${filterDateEnd && `&endDate=${filterDateEnd}`}`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		deleteItemFromInvoice: builder.mutation<IInvoiceItems, Partial<IInvoiceItems>>({
			query: (body) => ({
				url: `invoice/${body.id}/items`,
				method: 'DELETE',
				body: body,
			}),
			invalidatesTags: ['Invoice'],
		}),
		updateInvoice: builder.mutation<IInvoice, Partial<IInvoice>>({
			query(data) {
				return {
					url: `invoice/${data.id}`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoice'],
		}),
		submitBillingForPayment: builder.mutation<IInvoice, Partial<IInvoice>>({
			query(data) {
				return {
					url: `invoice/${data.id}/submitForPayment`,
					method: 'PATCH',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoice'],
		}),
		addPaymentToInvoice: builder.mutation<IInvoiceItems, Partial<IInvoiceAddPayment>>({
			query: (body) => ({
				url: `invoice/addPayment`,
				method: 'POST',
				body,
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoices'],
		}),
		invoiceWriteOff: builder.mutation<IInvoiceItems, Partial<IInvoiceWriteOff>>({
			query: (body) => ({
				url: `invoice/${body.id}/writeOff`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoices'],
		}),
		deleteDraftInvoice: builder.mutation<IInvoiceItems, Partial<IInvoice>>({
			query: (body) => ({
				url: `invoice/${body.id}/delete`,
				method: 'DELETE',
				body,
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoices'],
		}),
		getInvoiceBin: builder.query<
			IApiResponse<IPaginate<IInvoice[]>>,
			{
				pagination: { page: number; limit: number };
				searchTerm?: string;
				filterDateStart?: string;
				filterDateEnd?: string;
			}
		>({
			query: ({ pagination: { page = 1, limit = 10 }, searchTerm, filterDateStart, filterDateEnd }) =>
				`invoice-bin?page=${page}&limit=${limit}${searchTerm && `&searchTerm=${searchTerm}`}${
					filterDateStart && `&startDate=${filterDateStart}`
				}${filterDateEnd && `&endDate=${filterDateEnd}`}`,
			providesTags: [{ type: 'Invoices', id: 'LIST' }],
		}),
		retrieveDeletedInvoice: builder.mutation<IInvoice, Partial<IInvoice>>({
			query: (data) => ({
				url: `invoice-bin/${data.id}/retrieve`,
				method: 'PUT',
				body: data,
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoices'],
		}),
		clearAllDeletedInvoice: builder.mutation<IInvoice, void>({
			query: () => ({
				url: `invoice-bin/clearAll`,
				method: 'DELETE',
			}),
			invalidatesTags: (result, error) => [{ type: 'Invoice' }, 'Invoices'],
		}),
		cancelBilledInvoice: builder.mutation<IInvoice, Partial<IInvoiceCancellation>>({
			query: (body) => ({
				url: `invoice/${body.id}/cancel`,
				method: 'PATCH',
				body,
			}),
			invalidatesTags: (result, error, arg) => [{ type: 'Invoice', id: arg.id }, 'Invoices'],
		}),
	}),
});

export const {
	useCreateBlankInvoiceMutation,
	useCreateBlankInvoiceExternalMutation,
	useAddItemToInvoiceMutation,
	useGetInvoiceQuery,
	useGetInvoiceByIdQuery,
	useGetInvoiceDraftQuery,
	useGetInvoiceBilledQuery,
	useGetInvoicePaidQuery,
	useDeleteItemFromInvoiceMutation,
	useUpdateInvoiceMutation,
	useSubmitBillingForPaymentMutation,
	useAddPaymentToInvoiceMutation,
	useInvoiceWriteOffMutation,
	useDeleteDraftInvoiceMutation,
	useGetInvoiceBinQuery,
	useRetrieveDeletedInvoiceMutation,
	useClearAllDeletedInvoiceMutation,
	useCancelBilledInvoiceMutation,
	useGetInvoiceCancelledQuery,
} = invoiceApi;
