import { Button, Card, ListGroup, Spinner } from 'react-bootstrap';
import { useExportToPDF, useExportToXlsx } from '../../hooks';
import { CSVLink } from 'react-csv';

type ExportButtonProp = {
	isLoading?: boolean;
	buttonIcon?: Element;
	buttonText?: string;
	fileName: string;
	headerRow: { value: string | number }[];
	tableData: { value: string | number; [key: string]: string | number }[][];
};

const ExportButton = ({ isLoading = false, buttonIcon, buttonText, headerRow, fileName, tableData }: ExportButtonProp) => {
	const convertToPDF = useExportToPDF();
	const convertToXlsx = useExportToXlsx();

	return (
		<Button disabled={isLoading} className="table-icon position-relative">
			{isLoading ? (
				<Spinner className="text-white mt-1 mr-3" style={{ height: '1.3rem', width: '1.3rem' }} />
			) : (
				buttonIcon || <i className="bi bi-download mr-3" style={{ fontSize: '1em' }}></i>
			)}
			{buttonText || 'Export'}

			<div
				className="content card border shadow position-absolute mt-2 w-100"
				style={{ right: 'unset', left: '50%', transform: 'translateX(-50%)', minWidth: '8rem' }}
			>
				<Card className="rounded rounded-3 border-0 shadow-lg" style={{ cursor: 'pointer' }}>
					<ListGroup variant="flush">
						<ListGroup.Item
							onClick={() => {
								convertToPDF({
									fileName,
									headerRow: headerRow.map((item) => item.value),
									tableData: tableData.map((item) => item.map((subItem) => subItem.value)),
								});
							}}
						>
							PDF Format
						</ListGroup.Item>
					</ListGroup>

					<ListGroup variant="flush">
						<ListGroup.Item>
							<CSVLink
								{...{
									data: [headerRow.map((item) => item.value), ...tableData.map((item) => item.map((subItem) => subItem.value))],
									filename: `${fileName}.csv`,
								}}
								style={{ textDecoration: 'none' }}
							>
								CSV Format
							</CSVLink>
						</ListGroup.Item>
					</ListGroup>

					<ListGroup variant="flush">
						<ListGroup.Item
							onClick={() => {
								convertToXlsx({ fileName, headerRow, tableData });
							}}
						>
							XLS Format
						</ListGroup.Item>
					</ListGroup>
				</Card>
			</div>
		</Button>
	);
};
export default ExportButton;
