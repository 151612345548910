import React, { useState, useEffect } from 'react';
import Classes from './authstyle.module.css';
import FlexButton from '../../../components/elements/FlexButton';
import { countries } from '../inputTypes';
import InputFields from '../../../components/elements/FlexInput/input';
import { Col, Form, Row, Tooltip } from 'react-bootstrap';
import { specializationApi, useGetSpecializationDefaultQuery, useGetSpecializationsQuery } from '../../../app/services/specialization';
import { Input } from '../../../components/elements/Form/borderInput';
import { ErrorMessage, Field } from 'formik';
import { TagPicker, Form as RForm, InputPicker, Button, Whisper } from 'rsuite';
import { specializationList } from './reqAuth';
import { useGetCountriesQuery, useGetStatesForCountryQuery } from '../../../app/services/countries';
import { useFormikContext } from 'formik';
import { toast } from 'react-toastify';
import { ISpecialization } from '../../../interfaces/specialization';
import useCountriesAndStatesCustomHook from '../../../utils/countriesAndStatesData';
import { countriesWithStates } from '../../../data/CountryAndStateData';
import { InputPickerCustom } from '../../../components/elements/Form';
import { TIMEZONES } from '../../../constants';

interface hospitalType {
	values: { [key: string]: any };
	hospLoading: boolean;
	selectedValues: [];
	setSelectedValues: any;
}

const HospsReg = ({ values, hospLoading, selectedValues, setSelectedValues }: hospitalType) => {
	const [country, setCountry] = useState<any>([{ name: '' }]);
	const [countryValue, setCountryValue] = useState<string>('');
	const [state, setState] = useState<any>({});
	const [selectAll, setSelectAll] = useState(false);
	const [specializations, setSpecializations] = useState<ISpecialization[]>([]);

	const { data } = useGetCountriesQuery();

	const { data: stateData } = useGetStatesForCountryQuery({
		country: countryValue,
	});
	const {
		data: SpecData,
		error: errorMessage,
		isLoading,
		isSuccess: fetchSuccess,
		isError: fetchError,
	} = useGetSpecializationDefaultQuery();

	const { setSelectedCountry, selectedCountryStates } = useCountriesAndStatesCustomHook();

	useEffect(() => {
		setCountry(data?.data);
	}, [data]);

	useEffect(() => {
		setState(stateData?.data.states);
	}, [stateData]);

	// interface Option {
	//   label: string;
	//   value: string;
	// }

	interface Option {
		id: string;
		name: string;
		hospitalId: string;
		description: string;
		createdAt: Date;
		updatedAt: Date;
	}

	const options: Option[] = SpecData?.success ? SpecData?.payload : [];

	const fetch = () => {
		if (isLoading && fetchError) return toast.error('Error Fetching Specializations. Kindly try again.');
		SpecData?.success && SpecData?.payload && setSpecializations(SpecData.payload);
	};

	useEffect(() => {
		fetch();
	}, [data]);

	const handleSelectAll = () => {
		if (selectAll) {
			// If "Select All" is checked, deselect all items
			setSelectedValues([]);
		} else {
			// If "Select All" is not checked, select all items
			const allValues = options.map((option) => option.id);
			setSelectedValues(allValues);
		}
		// Toggle the "Select All" state
		setSelectAll((prev) => !prev);
	};

	const renderMenu = (menu: any) => {
		return (
			<>
				<div style={{ margin: '10px' }}>
					<Form.Check
						label={selectAll ? 'Deselect All' : 'Select All'}
						name="Radiology"
						type="checkbox"
						className="mx-4"
						value="rad"
						onChange={handleSelectAll}
					/>
				</div>
				{menu}
			</>
		);
	};

	// function SelectAll({ options, name }: any) {
	//   const [selectAll, setSelectAll] = useState(false);
	//   const { setFieldValue } = useFormikContext();

	//   const handleSelectAll = () => {
	//     if (selectAll) {
	//       setFieldValue(name, []);
	//     } else {
	//       const allValues = options.map((option) => option.value);
	//       setFieldValue(name, allValues);
	//     }
	//     setSelectAll((prev) => !prev);
	//   };

	//   return (
	//     <Button block color="blue" onClick={handleSelectAll}>
	//       {selectAll ? 'Deselect All' : 'Select All'}
	//     </Button>
	//   );
	// }

	// const { setFieldValue, values } = useFormikContext();

	// const handleChange = (value: string[]) => {
	//   setFieldValue('selectedValues', value);
	// };

	// const renderMenu = (menu: React.ReactElement) => {
	//   return (
	//     <>
	//       <SelectAll options={options} name="selectedValues" />
	//       {menu}
	//     </>
	//   );
	// };

	const deselectMultitpleTooltipForSpecialization = (
		<Tooltip>{`Remove the last item
    to deselect all.`}</Tooltip>
	);

	return (
		<div>
			<Form.Group>
				<Input placeholder="Hospital Name" name="name" className={Classes.inputs} DontshowError />
				<small className="text-danger">
					<ErrorMessage name="name" component="div" className="field-error text-danger" />
				</small>
			</Form.Group>
			<Form.Group className="mb-4">
				<Input placeholder="Hospital Address" name="address" className={Classes.inputs} DontshowError />
				<small className="text-danger">
					<ErrorMessage name="address" component="div" className="field-error text-danger" />
				</small>
			</Form.Group>
			<Row>
				<Col xs={11}>
					<Form.Group className="mb-4">
						<TagPicker
							block
							size="lg"
							placeholder="Select Specialization"
							className={Classes.inputs}
							data={SpecData?.success ? SpecData?.payload : []}
							labelKey="name"
							valueKey="id"
							value={selectedValues}
							onChange={setSelectedValues}
							renderMenu={renderMenu}
							preventOverflow={false}
							cleanable={false}
						/>

						<small className="text-danger">
							<ErrorMessage name="categories" component="div" className="field-error text-danger" />
						</small>
					</Form.Group>
				</Col>
				<Col xs={1}>
					<div className="mt-2">
						<Whisper placement="rightStart" trigger="hover" speaker={deselectMultitpleTooltipForSpecialization}>
							<i style={{ color: '#FFA000' }} className="bi bi-info-circle fa-lg"></i>
						</Whisper>
					</div>
				</Col>
			</Row>
			<Form.Group className="mb-4">
				<Field name="country">
					{({ field: { onChange, ...field }, meta }: any) => (
						<>
							<InputPickerCustom
								required={false}
								withoutLabel={true}
								size="lg"
								name="country"
								data={countriesWithStates || []}
								labelKey="country"
								placeholder="Country"
								valueKey="country"
								className={Classes.inputs}
								onChange={(v, e) => {
									setSelectedCountry(v);
									onChange({
										...e,
										target: {
											...e.target,
											id: 'country',
											name: 'country',
											value: v,
										},
									});
								}}
							/>
						</>
					)}
				</Field>
				<small className="text-danger">
					<ErrorMessage name="country" component="div" className="field-error text-danger" />
				</small>
			</Form.Group>
			<Form.Group>
				<Field name="state">
					{({ field: { onChange, ...field }, meta }: any) => (
						<>
							<InputPicker
								size="lg"
								name="state"
								data={selectedCountryStates || []}
								labelKey="label"
								valueKey="value"
								placeholder="State"
								className={Classes.inputs}
								onChange={(v, e) => {
									onChange({
										...e,
										target: {
											...e.target,
											id: 'state',
											name: 'state',
											value: v,
										},
									});
								}}
							/>
						</>
					)}
				</Field>
				<small className="text-danger">
					<ErrorMessage name="state" component="div" className="field-error text-danger" />
				</small>
			</Form.Group>
			<Form.Group>
				<Input placeholder="City" name="city" className={Classes.inputs} DontshowError />
				<small className="text-danger">
					<ErrorMessage name="city" component="div" className="field-error text-danger" />
				</small>
			</Form.Group>
			<Form.Group className="mt-4">
				<Field name="timeZoneLabel">
					{({ field: { onChange, ...field }, meta }: any) => (
						<>
							<InputPicker
								size="lg"
								name="timeZoneLabel"
								data={TIMEZONES || []}
								labelKey="label"
								valueKey="label"
								placeholder="Select Time Zone"
								className={Classes.inputs}
								onChange={(value, event) => {
									console.log(value, event);
									onChange({
										...event,
										target: {
											...event.target,
											id: 'timeZoneLabel',
											name: 'timeZoneLabel',
											value: value,
										},
									});
								}}
							/>
							<small className="text-danger">
								<ErrorMessage name="timeZoneLabel" component="div" className="field-error text-danger" />
							</small>
						</>
					)}
				</Field>
			</Form.Group>
			<Form.Group className="mb-5">
				<Input placeholder="Hospital Registration Number" name="rcCode" className={Classes.inputs} DontshowError />
				<small className="text-danger">
					<ErrorMessage name="rcCode" component="div" className="field-error text-danger" />
				</small>
			</Form.Group>

			<FlexButton color="flexblue" disabled={false} type="submit" style={{ padding: '.43rem 4rem', backgroundColor: '#0F4E71' }}>
				{/* {loading?'Loading...':'Next'} */}
				{hospLoading ? 'Loading...' : 'Proceed'}
			</FlexButton>
		</div>
	);
};

export default HospsReg;
