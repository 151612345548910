import { createApi } from '@reduxjs/toolkit/query/react';
import { IApiResponse, IPaginate } from '../../interfaces/common';
import { IPatients } from '../../interfaces/patients';
import { Admission } from '../../interfaces/reports';
import { baseQuery } from './common';

export const patientsApi = createApi({
	reducerPath: 'patients',
	baseQuery: baseQuery,
	tagTypes: ['Patients', 'Patient'],
	refetchOnFocus: true,
	refetchOnMountOrArgChange: true,
	refetchOnReconnect: true,
	endpoints: (builder) => ({
		getPatients: builder.query<IApiResponse<IPaginate<IPatients[]>>, { pagination: { page: number; limit: number | string } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `/patients?page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Patients', id: 'LIST' }],
			keepUnusedDataFor: 300,
		}),
		getAdmittedPatients: builder.query<IApiResponse<IPaginate<Admission[]>>, { pagination: { page: number; limit: number | string } }>({
			query: ({ pagination: { page = 1, limit = 10 } }) => `/patient-admissions?status[]=admitted&page=${page}&limit=${limit}`,
			providesTags: [{ type: 'Patients', id: 'LIST' }],
			keepUnusedDataFor: 300,
		}),
		getPatientById: builder.query<IApiResponse<IPatients>, string>({
			query: (id) => `/patients/${id}`,
			keepUnusedDataFor: 300,
			providesTags: (result, error, arg) => [{ type: 'Patient', id: arg }],
		}),
		searchPatients: builder.query<
			IApiResponse<IPaginate<IPatients[]>>,
			{
				name?: string;
				email?: string;
				phoneNumber?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ name, email, phoneNumber, pagination: { page = 1, limit = 10 } }) =>
				`/patients/?page=${page}&limit=${limit}${name && `&name=${name}`}`,
			providesTags: [{ type: 'Patients' }],
		}),
		filterPatients: builder.query<
			IApiResponse<IPaginate<IPatients[]>>,
			{
				name?: string;
				pagination: { page?: number; limit?: number };
			}
		>({
			query: ({ name, pagination: { page = 1, limit = 10 } }) => `/patients/search?page=${page}&limit=${limit}${name && `&name=${name}`}`,
			providesTags: [{ type: 'Patients' }],
		}),
		updatePatient: builder.mutation<IPatients, Partial<IPatients>>({
			query(data) {
				return {
					url: `patients/${data.id}`,
					method: 'PUT',
					body: data,
				};
			},
			invalidatesTags: (result, error, arg) => [{ type: 'Patient', id: arg.id }],
		}),

		getPatientByHospitalPatientId: builder.query<IApiResponse<IPaginate<IPatients[]>>, string | undefined>({
			query: (patientId) => `patients/search?hospitalPatientId=${patientId}`,
			providesTags: [{ type: 'Patients' }],
		}),

		getAllPatients: builder.query<IApiResponse<IPaginate<IPatients[]>>, void>({
			query: () => `/patients?limit=${'All'}`,
			providesTags: [{ type: 'Patients', id: 'LIST' }],
		}),

		createPatient: builder.mutation<IPatients, Partial<IPatients>>({
			query: (body) => ({
				url: `patients`,
				method: 'POST',
				body,
			}),
			invalidatesTags: ['Patients'],
		}),

		newPatientSearch: builder.query<
			IApiResponse<IPaginate<IPatients[]>>,
			{
				filterType: string;
				searchText?: string;
				pagination: { page: number; limit: number };
				startDate?: string;
				endDate?: string | Date;
			}
		>({
			query: ({ filterType, searchText, pagination: { page = 1, limit = 10 }, startDate, endDate }) =>
				`/patients/search?page=${page}&limit=${limit}${filterType === `name` ? `&name=${searchText}` : ``}${
					filterType === `civisId` ? `&civisId=${searchText}` : ``
				}${filterType === `email` ? `&email=${searchText}` : ``}${filterType === `phoneNumber` ? `&phoneNumber=${searchText}` : ``}${
					filterType === `hospitalPatientId` ? `&hospitalPatientId=${searchText}` : ``
				}${filterType === `isDependant` ? `&isDependant=${true}` : ``}${filterType === `active` ? `&status=${`active`}` : ``}${
					filterType === `deceased` ? `&status=${`deceased`}` : ``
				}${startDate ? `&startDate=${startDate}` : ''}${endDate ? `&endDate=${endDate}` : ''}`,
			providesTags: [{ type: 'Patients' }],
		}),
	}),
});

export const {
	useGetPatientsQuery,
	useGetPatientByIdQuery,
	useUpdatePatientMutation,
	useSearchPatientsQuery,
	usePrefetch,
	useGetPatientByHospitalPatientIdQuery,
	useGetAllPatientsQuery,
	useCreatePatientMutation,
	useNewPatientSearchQuery,
	useGetAdmittedPatientsQuery,
} = patientsApi;
