import React, { useState, ChangeEvent, useEffect, useContext } from 'react';
import { Form, Container, Row, Modal, Button, Alert } from 'react-bootstrap';
import civisLogo from '../../../assets/images/civisLogo.svg';
import signupImg from '../../../assets/images/signupImg.svg';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { registerAsync, selectStateValues } from '../../../app/hospitalRedux/userSlice';
import classes from './authstyle.module.css';
import HospReg from './hopsReg';
import AdminReg from './adminReg';
import CreatePassword from './createPassword';
import Swal from 'sweetalert2';
import { useFormik, FormikProvider, Formik } from 'formik';
import * as yup from 'yup';
import FlexButton from '../../../components/elements/FlexButton';
import { getAllCountries, getAllStates, getRc } from './reqAuth';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { TIMEZONES } from '../../../constants';

export const Toast = Swal.mixin({
	toast: true,
	position: 'top-right',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	didOpen: (toast) => {
		toast.addEventListener('mouseenter', Swal.stopTimer);
		toast.addEventListener('mouseleave', Swal.resumeTimer);
	},
});

const { REACT_APP_BASEURL } = process.env;

const config = {
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
};

const Signup = () => {
	const [showAdmin, setshowAdmin] = useState(false);
	const [showHosp, setShowHosp] = useState(true);
	const [createPassword, setCreatePassword] = useState(false);

	const [hosLoading, setHosLoading] = useState(false);
	const [submitLoading, setSubmitLoading] = useState(false);

	const [password, setPassword] = useState('');
	const [passwordAgain, setPasswordAgain] = useState('');
	const [isChecked, setIsChecked] = useState(false);
	const [passValidation, setPassValidation] = useState(false);

	const [selectedValues, setSelectedValues] = useState<any>([]);

	const history = useHistory();

	const initialVal = {
		name: '',
		address: '',
		// categories:[],
		country: '',
		state: '',
		timeZoneLabel: '',
		timeZoneOffset: '',
		city: '',
		rcCode: '',
	};

	const initialAdmin = {
		fullname: '',
		phoneNumber: '',
		email: '',
		country: '',
		state: '',
		city: '',
		address: '',
		dialcode: '',
		gender: '',
	};

	const [hospitalValue, setHospitalValue] = useState<typeof initialVal>(initialVal);
	const [adminValue, setAdminValue] = useState<typeof initialAdmin>(initialAdmin);

	const validationSchema = yup.object({
		name: yup.string().required('Hospital Name is a required field').trim().min(2),
		address: yup.string().trim().required('Address is a required field').min(3),
		// categories:yup.array().required('Category is a required field').nullable().min(1),
		country: yup.string().required('Country is a required field'),
		state: yup.string().required('State is a required field'),
		timeZoneLabel: yup.string().required('Timezone is a required field'),
		city: yup.string().required('City is a required field'),
		rcCode: yup.string().required('Rc code is a required field'),
	});

	const validationAdmin = yup.object({
		fullname: yup.string().trim().required('Full Name is a required, must include a Last Name').min(2),
		phoneNumber: yup
			.string()
			.required('Phone Number is a required field')
			.matches(/^[0-9]+$/, 'Must be only digits')
			.min(8, 'minimum must be 8 characters')
			.max(17, 'maximum must be 17 characters'),
		address: yup.string().trim().required('Address is a required field').min(2),
		email: yup.string().email('Must be a valid email').required('Email is a required field'),
		dialcode: yup.string().required('Dial Code is a required field'),
		country: yup.string().required('Country is a required field'),
		state: yup.string().required('State is a required field'),
		city: yup.string().required('City is a required field'),
		gender: yup.string(),
	});

	let showText = '';
	if (showHosp) {
		showText = 'Hospital Registration';
	} else if (showAdmin) {
		showText = 'Admin Registration';
	} else if (createPassword) {
		showText = 'Create Password';
	}

	const letSubmit = async (values: typeof initialVal) => {
		if (selectedValues.length == 0) {
			Toast.fire({
				icon: 'warning',
				title: 'Hospital must have a specialization',
			});
		} else {
			try {
				setHosLoading(true);
				const rcCode: any = await getRc(values.rcCode);
				if (rcCode?.does_hospital_exist === false) {
					setShowHosp(false);
					setHosLoading(false);
					setshowAdmin(true);
				} else {
					setHosLoading(false);
					Toast.fire({
						icon: 'warning',
						title: 'Hospital Already Exists',
					});
				}
			} catch (error) {
				setHosLoading(false);
				Toast.fire({
					icon: 'warning',
					title: 'Kindly try again',
				});
			}
		}
	};

	const onSubmit = async () => {
		setSubmitLoading(true);
		const data = {
			user: {
				...adminValue,
				title: adminValue.gender == 'Male' ? 'Mr' : 'Ms',
				password: password,
			},
			hospital: {
				...hospitalValue,
				specializations: selectedValues,
			},
		};

		const val = {
			client_name: adminValue.fullname,
			sender_name: 'Civis Health App',
			to: adminValue.email,
			from: 'civishealth@email.com',
			hospital_name: hospitalValue.name,
		};
		localStorage.setItem('signupInfo', JSON.stringify(val));
		try {
			const converted = JSON.stringify(data);
			console.log('here is me now: ', converted);
			const res: any = await axios.post(`${REACT_APP_BASEURL}hospitals`, data, config);

			if (res?.data?.success) {
				setSubmitLoading(false);
				localStorage.setItem('email', adminValue.email);
				history.push(`/${history.location.pathname.split('/')[1]}/email`);
			}
		} catch (error: any) {
			console.log(error.response);
			setSubmitLoading(false);
			Toast.fire({
				icon: 'warning',
				title: 'error occured',
			});
		}
	};

	return (
		<div
			style={{
				backgroundColor: 'whitesmoke',
				minHeight: '100vh',
				position: 'static',
			}}
		>
			<div className="bg-white shadow-box-example z-depth-5 text-center mb-3">
				<img src={civisLogo} width="11%" alt="logo" />
			</div>
			<Row>
				<div className="col-md-12 col-lg-6 bg-white" style={{ overflowY: 'scroll', height: '85vh' }}>
					<div className={classes.inputContent}>
						<div>
							<h4 className="text-center mb-2"> {showText} </h4>
							{showHosp && (
								<Formik
									enableReinitialize={true}
									validationSchema={validationSchema}
									validateOnChange={true}
									initialValues={initialVal}
									onSubmit={(data: typeof initialVal) => {
										letSubmit(data);
										setHospitalValue({ ...data, timeZoneOffset: TIMEZONES.find((zone) => zone.label === data.timeZoneLabel)?.utc || '' });
									}}
								>
									{({ values, errors, handleChange, isValid, isInitialValid, isSubmitting, handleSubmit }) => (
										<>
											<form className="py-3" onSubmit={handleSubmit}>
												<HospReg selectedValues={selectedValues} setSelectedValues={setSelectedValues} values={values} hospLoading={hosLoading} />
											</form>
										</>
									)}
								</Formik>
							)}

							{showAdmin && (
								<Formik
									enableReinitialize={true}
									validationSchema={validationAdmin}
									validateOnChange={true}
									initialValues={initialAdmin}
									onSubmit={(data: typeof initialAdmin) => {
										setAdminValue(data);
										setshowAdmin(false);
										setCreatePassword(true);
										// console.log(data);
									}}
								>
									{({ values, errors, handleChange, isValid, isInitialValid, isSubmitting, handleSubmit }) => (
										<>
											<form className="py-3" onSubmit={handleSubmit}>
												<AdminReg values={values} adminLoading={hosLoading} />
											</form>
										</>
									)}
								</Formik>
							)}

							{createPassword && (
								<CreatePassword
									setPassword={setPassword}
									setPasswordAgain={setPasswordAgain}
									passwordValue={password}
									passwordAgain={passwordAgain}
									submitAllform={onSubmit}
									setIsChecked={setIsChecked}
									isChecked={isChecked}
									setPassValidation={setPassValidation}
									passValidation={passValidation}
									submitLoading={submitLoading}
								/>
							)}
						</div>
						{showHosp && (
							<p
								style={{
									marginTop: '1.5rem',
									fontSize: '.9rem',
									textAlign: 'center',
								}}
							>
								Already have an Account?{' '}
								<a style={{ cursor: 'pointer' }} className="text-primary" onClick={() => history.push('/hospital/login')}>
									{' '}
									Sign In{' '}
								</a>
							</p>
						)}
					</div>
				</div>
				<div style={{ objectFit: 'cover' }} className="col-md-12 im col-lg-6 justify-self-bottom">
					<img src={signupImg} className="thumbnail d-block" alt="Responsive image" />
				</div>
			</Row>
		</div>
	);
};

export default Signup;
